import React from "react";
import * as styles from "./ItemsList.module.scss";

export const ItemsList = ({ content }) => {
  return (
    <div className={styles.ItemsList}>
      <div className={styles.wrapper}>
        {content?.map(({ id, items, label }, index) => (
          <div key={id + index} className={styles.item}>
            {id && (
              <img className={styles.icon} src={`/icons/${id}.png`} alt={id} />
            )}
            <div>{label}</div>
            <div className={styles.number}>{items}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
