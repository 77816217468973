.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 512px;
}

.search {
  font-size: 14px;
  font-weight: 500;
  color: #000;

  .results {
    margin-left: 8px;
    font-weight: 400;
    opacity: 0.4;
  }
}
