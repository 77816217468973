import React from "react";
import * as styles from "./DateFilter.module.scss";

export const DateFilter = ({ content, height }) => {
  return (
    <div className={styles.DateFilter}>
      <div className={styles.wrapper}>
        {content?.map((item) =>
          item !== "---" ? (
            <div key={item} className={styles.item}>
              {item}
            </div>
          ) : (
            <div key={item} className={styles.divider} />
          )
        )}
      </div>
    </div>
  );
};
