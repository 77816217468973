.DateFilter {
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 12px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  user-select: none;
  transition: 0.2s background ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.divider {
  opacity: 5%;
  border-bottom: 1px solid #000;
}
