import React from "react";
import ChevronDownSmall from "./../../public/assets/icons/ChevronDownSmall.svg";
import * as styles from "./Trigger.module.scss";

export const Trigger = ({
  label,
  icon,
  isOpen,
  disabled,
  onClick,
  targetId,
  triggerRef,
  ...props
}) => {
  return (
    <>
      <button
        className={[styles.Trigger, isOpen && styles.isOpen].join(" ")}
        ref={triggerRef}
        onClick={onClick}
        aria-expanded={isOpen}
        aria-controls={targetId}
        {...props}
      >
        <div className={styles.icon}>{icon}</div>
        {label}
        {!disabled && <ChevronDownSmall className={styles.caret} />}
      </button>
    </>
  );
};
