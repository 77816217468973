import React, { useState, useRef, useEffect, createRef } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import * as styles from "./MorphingMenu.module.scss";
import { Trigger } from "./Trigger";

export const MorphingMenu = ({ data, filterConfig, options }) => {
  const [activeTriggerId, setActiveTriggerId] = useState(null);
  const [previousLayerWidth, setPreviousLayerWidth] = useState(0);
  const [triggerX, setTriggerX] = useState(0);
  const [isMorphing, setIsMorphing] = useState(true);
  const [contentDimensions, setContentDimensions] = useState({
    width: 0,
    height: 0,
  });

  const menuRef = useRef(null);
  const contentRef = useRef(null);

  const triggerRefs = useRef({});

  const getTriggerRef = (id) => {
    if (!triggerRefs.current[id]) {
      triggerRefs.current[id] = createRef();
    }
    return triggerRefs.current[id];
  };

  const getTriggerX = (id) => {
    const triggerEl = triggerRefs.current[id]?.current;
    if (triggerEl) {
      const rect = triggerEl.getBoundingClientRect();
      return rect.left;
    }
    return 0;
  };

  useEffect(() => {
    if (contentRef.current) {
      const contentRect = contentRef.current.getBoundingClientRect();
      setContentDimensions({
        width: contentRect.width,
        height: contentRect.height,
      });
    }
  }, [activeTriggerId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        activeTriggerId
      ) {
        handleClick(activeTriggerId);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeTriggerId]);

  const handleClick = (id) => {
    setIsMorphing(activeTriggerId !== null);
    const menuRect = menuRef.current.getBoundingClientRect();
    setTriggerX(getTriggerX(id) - menuRect.left);
    setActiveTriggerId(activeTriggerId === id ? null : id);
    setPreviousLayerWidth(filterConfig[id]?.width);

    const morphingLayer = menuRef.current.querySelector(
      `.${styles.morphingLayer}`
    );
    if (morphingLayer) {
      morphingLayer.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const getContent = (contentId) => {
    return data.find(({ id }) => id === contentId)?.content;
  };

  const content = getContent(activeTriggerId);
  const activeFilterConfig = filterConfig[activeTriggerId];

  const Component = activeFilterConfig?.component;

  return (
    <div ref={menuRef} className={styles.container}>
      <div className={styles.triggerContainer}>
        {Object.keys(filterConfig).map((id) => {
          const { label, icon } = filterConfig[id];
          return (
            <Trigger
              key={id}
              isOpen={id === activeTriggerId}
              triggerRef={getTriggerRef(id)}
              label={label}
              icon={icon}
              onClick={() => handleClick(id)}
            />
          );
        })}
        <div className={styles.spacer} />
        {Object.keys(options).map((id) => {
          const { label, icon } = options[id];
          return <Trigger disabled key={id} label={label} icon={icon} />;
        })}
      </div>

      <div
        className={`${styles.morphingLayer} ${
          activeTriggerId ? styles.visible : styles.hidden
        } ${isMorphing && styles.morph}`}
        style={{
          transform: `translateX(${triggerX}px)`,
          height: Math.min(
            contentDimensions.height,
            activeFilterConfig?.maxHeight || 0
          ),
          width: activeFilterConfig?.width || previousLayerWidth,
        }}
      >
        <TransitionGroup className={styles.contentWrapper}>
          {activeTriggerId && (
            <CSSTransition
              className={`${styles.groupContainer} ${
                isMorphing ? styles.isMorphing : ""
              }`}
              key={activeTriggerId}
              timeout={{
                enter: 1300,
                exit: 1250,
              }}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
              }}
            >
              <div ref={contentRef}>
                <Component
                  content={content}
                  width={activeFilterConfig?.width}
                  height={activeFilterConfig?.maxHeight}
                />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div>
  );
};
