.ItemsList {
  width: 100%;
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 13px;
  border-radius: 48px;
  font-size: 14px;
  font-weight: 400;
  transition: 0.2s background ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.number {
  opacity: 0.4;
}
