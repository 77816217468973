.container {
  position: relative;
}

.triggerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.spacer {
  flex-grow: 1;
}

.morphingLayer {
  position: absolute;
  margin-top: 0.5rem;
  background-color: #fff;
  overflow: hidden;
  overflow-y: scroll;
  gap: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  &.morph {
    transition: transform 0.2s ease-out, opacity 0.3s ease, width 0.25s ease-out,
      height 0.25s ease-in-out;
  }

  &.hidden {
    opacity: 0;
  }

  &.visible {
    opacity: 1;
  }
}

.contentWrapper {
  position: relative;
}

.groupContainer {
  width: 100%;
  position: absolute;
}

.enter {
  width: 100%;
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.isMorphing.enterActive {
  transition-delay: 0.1s;
}

.exit {
  width: 100%;
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 0.25s ease-out;
}
