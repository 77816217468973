import React from "react";
import * as styles from "./PeopleFilter.module.scss";
import Search from "./../../public/assets/icons/Search.svg";

export const PeopleFilter = ({ content, height }) => (
  <div className={styles.PeopleFilter}>
    <div className={styles.inputWrapper}>
      <input type="text" placeholder="Search for people or teams..." />
      <div className={styles.startAdornment}>
        <Search />
      </div>
    </div>
    <div className={styles.divider} />
    <div className={styles.list}>
      {content?.map(({ name, email }, index) => (
        <div key={email} className={styles.item}>
          <img
            className={styles.avatar}
            src={`/avatars/${(index % 10) + 1}.jpg`}
            alt={name}
          />
          <span className={styles.name}>{name}</span>
          <span className={styles.email}>{email}</span>
        </div>
      ))}
    </div>
  </div>
);
