import React from "react";
import { MorphingMenu } from "./components/MorphingMenu";
import Folder from "./../public/assets/icons/Folder.svg";
import Calendar from "./../public/assets/icons/Calendar.svg";
import Person from "./../public/assets/icons/Person.svg";
import Settings from "./../public/assets/icons/Settings.svg";
import * as styles from "./App.module.scss";

import { ItemsList } from "./components/ItemsList";
import { PeopleFilter } from "./components/PeopleFilter";
import { DateFilter } from "./components/DateFilter";

import "./styles/mixins.scss";
import "./styles/styles.scss";
import "./styles/fonts.scss";

export function App() {
  const results = [
    {
      id: "apps",
      content: [
        { id: "", items: 24, label: "All" },
        { id: "google", items: 2, label: "Google Drive" },
        { id: "azure", items: 2, label: "Azure" },
        { id: "slack", items: 6, label: "Slack" },
        { id: "confluence", items: 2, label: "Confluence" },
        { id: "notion", items: 1, label: "Notion" },
        { id: "github", items: 7, label: "Github" },
        { id: "dropbox", items: 4, label: "Dropbox" },
        { id: "sharepoint", items: 0, label: "Sharepoint" },
        { id: "google", items: 2, label: "Google Drive" },
        { id: "azure", items: 2, label: "Azure" },
        { id: "slack", items: 6, label: "Slack" },
        { id: "confluence", items: 2, label: "Confluence" },
        { id: "notion", items: 1, label: "Notion" },
        { id: "github", items: 7, label: "Github" },
        { id: "dropbox", items: 4, label: "Dropbox" },
        { id: "sharepoint", items: 0, label: "Sharepoint" },
      ],
    },
    {
      id: "people",
      content: [
        { name: "John Doe", email: "john.doe@acme.com" },
        { name: "Jane Smith", email: "jane.smith@acme.com" },
        { name: "Mike Johnson", email: "mike.johnson@acme.com" },
        { name: "Sarah Williams", email: "sarah.williams@acme.com" },
        { name: "Margie Ernser", email: "margie.ernser@acme.com" },
        { name: "Richard Tillman", email: "richard.tillman@acme.com" },
        { name: "John Doe", email: "john.doe2@acme.com" },
        { name: "Jane Smith", email: "jane.smith2@acme.com" },
        { name: "Mike Johnson", email: "mike.johnson2@acme.com" },
        { name: "Sarah Williams", email: "sarah.williams2@acme.com" },
        { name: "Margie Ernser", email: "margie.ernser2@acme.com" },
        { name: "Richard Tillman", email: "richard.tillman2@acme.com" },
      ],
    },
    {
      id: "modified",
      content: [
        "Any time",
        "Today",
        "Last 7 days",
        "Last 30 days",
        "This year",
        "---",
        "Custom range",
      ],
    },
    { id: "more" },
  ];

  const filterConfig = {
    apps: {
      label: "Apps",
      icon: <Folder />,
      component: ItemsList,
      width: 267,
      maxHeight: 350,
    },
    people: {
      label: "People",
      icon: <Person />,
      component: PeopleFilter,
      width: 296,
      maxHeight: 308,
    },
    modified: {
      label: "Modified",
      icon: <Calendar />,
      component: DateFilter,
      width: 312,
      maxHeight: 264,
    },
  };

  const options = {
    more: {
      label: "More",
      icon: <Settings />,
      width: 312,
      maxHeight: 264,
    },
  };

  return (
    <div className={styles.app}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.search}>
            Search<span className={styles.results}>24 results</span>
          </div>
          <MorphingMenu
            data={results}
            filterConfig={filterConfig}
            options={options}
          />
        </div>
      </div>
    </div>
  );
}
