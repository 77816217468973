.Trigger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px 0px 16px;
  gap: 8px;
  height: 36px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 48px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  user-select: none;

  border: none;
  transition: background-color 0.3s ease;

  .icon {
    display: flex;
    opacity: 0.6;
    transition: opacity 0.2s ease-out;
  }

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.12);
    .icon {
      opacity: 1;
    }
  }

  .caret {
    opacity: 0.6;
    transition: transform 0.2s ease-out;
  }
}

.isOpen {
  .caret {
    transform: rotate(180deg);
  }
  .icon {
    opacity: 1;
  }
}
