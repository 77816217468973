.PeopleFilter {
  width: 100%;
  padding: 6px 12px 12px 12px;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 48px;
  padding: 8px 16px;
  font-size: 14px;
  transition: 0.2s background ease-out;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.avatar {
  width: 16px;
  height: 16px;
  overflow: hidden;
  border-radius: 4px;
  flex-shrink: 0;
  font-size: 14px;
}

.name {
  font-weight: 500;
  flex-shrink: 0;
}

.email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 0.4;
}

.inputWrapper {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 36px;

  input {
    padding: 8px 12px 8px 42px;
    width: 100%;
    border: 0;
    font-size: 14px;
    height: 24px;
  }

  .startAdornment {
    padding: 3px 16px 0 16px;
    position: absolute;
  }
}

.divider {
  opacity: 5%;
  border-bottom: 1px solid #000;
  margin: 4px 0;
}
